import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ApiHeader = _resolveComponent("ApiHeader")!
  const _component_CallbackResponse = _resolveComponent("CallbackResponse")!
  const _component_SampleCode = _resolveComponent("SampleCode")!
  const _component_LiveCallbackTester = _resolveComponent("LiveCallbackTester")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ApiHeader, {
      url: _ctx.$store.state.AuthModule.user.url_debit,
      method: "POST",
      type: "live"
    }, null, 8, ["url"]),
    _createVNode(_component_CallbackResponse, {
      success: _ctx.successResponse,
      error: _ctx.errorResponse,
      errorComment: _ctx.errorComment
    }, null, 8, ["success", "error", "errorComment"]),
    _createVNode(_component_SampleCode, null, {
      php: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.phpCode), 1)
      ]),
      asp: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.aspCode), 1)
      ]),
      net: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.aspNetCode), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_LiveCallbackTester, {
      url: _ctx.$store.state.AuthModule.user.url_debit,
      page: "DEBIT"
    }, null, 8, ["url"])
  ], 64))
}